export const menuItems = [
    {
        id: 1,
        label: 'header.dashboards.text',
        icon: 'monitor',
        subItems: [
            {
                id: 2,
                label: 'menuitems.dashboards.label',
                link: '/',
                parentId: 1
            },
        ]
    },


    {
        id: 100,
        label: 'menuitems.road.text',
        icon: 'monitor',
        subItems: [
            {
                id: 101,
                label: 'menuitems.road.label',
                link: '/road/main',
                parentId: 100
            },

            {
                id: 102,
                label: 'menuitems.road.add',
                icon: 'monitor',
                link: '/road/new',
                parent : 100
            },
        ]
    },

    {
        id: 201,
        label: 'menuitems.issue.label',
        icon: 'monitor',
        subItems: [
            {
                id: 202,
                label: 'menuitems.issue.text',
                icon: 'monitor',
                link: '/issue/list',
                parent : 201
            },

            {
                id: 203,
                label: 'menuitems.issue.add',
                icon: 'monitor',
                link: '/road/new',
                parent : 201
            },
        ]
    },

    {
        id: 301,
        label: 'menuitems.measure.label',
        icon: 'monitor',
        subItems: [
            {
                id: 302,
                label: 'menuitems.measure.text',
                icon: 'monitor',
                link: '/measure/task/list',
                parent : 301
            },
        ]
    },

  
];
