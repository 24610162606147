<script>


import i18n from "../i18n";
import { Session,Local } from "../helpers/storage";

/**
 * Topbar component
 */
export default {
  data () {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/china.jpg"),
          language: "zh",
          title: "中文",
        },
      
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
    }
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    layoutscroll: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },

  computed:  {
    profile() {
      let profile = Session.get("profile")
      if (!profile) {
        return {
          name : '未登录'
        }
      } else {
        return profile
      }
    }
  },

  methods: {

    logout() {

      Session.remove('profile')
      Session.remove('token')
      Local.remove('profile')
      Local.remove('token')

      this.$router.push({name : "login"})
     
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
    },
  },
  components: {
   
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    layoutscroll: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case false:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
            case true:
              document.body.setAttribute("data-layout-scrollable", "true");
              break;
            default:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <router-link to="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="@/assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="@/assets/images/logo-dark.png" alt="" height="22" />
          </span>
        </router-link>

        <router-link to="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="@/assets/images/logo-sm.png" alt=""  />
          </span>
          <span class="logo-lg">
            <img src="@/assets/images/logo-light.png" alt="" height="22" />
          </span>
        </router-link>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
        @click="toggleMenu"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>



    </div>

    <div class="d-flex">
    

     


      <b-dropdown
        variant="white"
        right
        toggle-class="header-item user text-start d-flex align-items-center"
        class="dropdown d-inline-block"
        menu-class="dropdown-menu-end pt-0"
      >
        <template v-slot:button-content>
        
          <span class="ms-2 d-none d-sm-block user-item-desc">
            <span class="user-name">{{ profile.user_name }}</span>
            <span class="user-sub-title">{{ profile.dept_name}}</span>
          </span>
        </template>

        <div class="p-3 bg-primary border-bottom">
          <h6 class="mb-0 text-white">{{ profile.user_name }}</h6>
          <p class="mb-0 font-size-11 text-white-50 fw-semibold">
            {{ profile.account }}
          </p>
        </div>
       
        <router-link
          class="dropdown-item d-flex align-items-center"
          to="/user/settings"
          ><i
            class="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"
          ></i>
          <span class="align-middle">{{ $t('header.profilewidget.settings') }}</span
          ><span class="badge badge-soft-success ms-auto"
            >New</span
          ></router-link
        >
        <a class="dropdown-item" href="javascript:void(0);" @click="logout()"
          ><i
            class="mdi mdi-logout text-muted font-size-16 align-middle me-1"
          ></i>
          <span class="align-middle">{{ $t('header.profilewidget.logout') }}</span></a
        >
      </b-dropdown>
    </div>
  </div>
</template>
