import axios from 'axios';

import qs from 'qs';

import { Session } from "@/helpers/storage";
import router from '../router/index'

// 配置新建一个 axios 实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	timeout: 50000,
	paramsSerializer: {
		serialize(params) {
			return qs.stringify(params, { allowDots: true });
		},
	},
});

// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		// // 在发送请求之前做些什么 token
		// if (Session.get('token')) {
		// 	config.headers['Authorization'] = `${Session.get('token')}`;
		// }
		config.headers['Authorization'] ='Bearer '+ Session.get('token')
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		if (response.config.url === '/login') {
			//Session.set('token', response.data.token)
		} else {
			const res = response.data;
			if (res.err === 401) {
				//window.location.href = '/login';
				router.push({'name' : 'login'})
			} else {
				return res;
			}
		}
	},
	(error) => {
		if (error.response.status == 401) {
			router.push({'name' : 'login'})
		} else {
			return Promise.reject(error);
		}
	}
);

// 导出 axios 实例
export default service;
