export const menuItems = [
    {
        id: 1,
        label: 'menuitems.dashboards.text',
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboards.label',
        icon: 'monitor',
        link: '/',
        // badge: {
        //     variant: 'badge-soft-secondary',
        //     text: 'menuitems.sales.badge',
        // },
    },

    {
        id: 100,
        label: 'menuitems.produce.label',
        isTitle: true
    },
    {
        id: 101,
        label: 'menuitems.produce.label',
        icon: 'monitor',
        subItems: [
            {
                id: 102,
                label: 'menuitems.produce.task_list',
                icon: 'monitor',
                link: '/produce/task_list',
                parent : 101
            },
          
            {
                id: 102,
                label: 'menuitems.produce.text',
                icon: 'monitor',
                link: '/produce/produce_list',
                parent : 101
            },
        ]
    },

    {
        id: 201,
        label: 'menuitems.storage.label',
        icon: 'monitor',
        subItems: [
            {
                id: 202,
                label: 'menuitems.storage.text',
                icon: 'monitor',
                link: '/stock/list',
                parent : 201
            },


            {
                id: 203,
                label: 'menuitems.storage.assemble',
                icon: 'monitor',
                link: '/assemble/list',
                parent : 201
            },

           
        ]
    },

    {
        id: 301,
        label: 'menuitems.system.label',
        icon: 'monitor',
        subItems: [
            {
                id: 302,
                label: 'menuitems.system.text',
                icon: 'monitor',
                link: '/system/basic',
                parent : 301
            },

            {
                id: 303,
                label: 'menuitems.system.produce',
                icon: 'monitor',
                link: '/system/produce',
                parent : 301
            },
        ]
    },

];

